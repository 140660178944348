.callForPaper{
    width : 70%;
    margin: 0 auto;
    min-height: 800px;
}
.posterDownload{
    margin: 30px auto 0px !important;
    font-size: 1.2rem !important;
    text-align: center;
}

.cfpNote1{
    margin : 20px 0 0;
    text-align: justify;
    line-height: 30px;
    text-indent: 30px;
}
.callForPaper .title{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0 0;
}

.callForPeperPointsDiv{
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
}

.callForPeperPointsDiv p{
    line-height: 30px;
}
.callForPeperPointsDiv svg{
   margin-top: 3px;
}


.submissionBtn{
    text-decoration: none;
    display: block;
    font-size: 1.3em;
    width : 350px;
    /* height : 70px; */
    text-align: center;
    padding : 10px 20px;
    margin : 20px auto 30px;
    border-radius: 5px;
    background-color: rgb(255, 111, 0);
    color: rgb(36, 24, 80);
}

.submissionBtn:hover{
    background-color: #061D86;
    color: white;
}

@media screen and (max-width:500px) {
    .callForPaper{
        width: 94%;
    }
}