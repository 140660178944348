.registrationBody {
  margin: 0px auto;
  width: 90%;
  max-width: 900px;
  padding-bottom: 100px;

}

.tableTitle {
  text-align: center;
  padding: 30px 0px 15px 0px;
}

.tableTitle label {
  font-size: 22px;
  font-weight: 600;
  border-bottom: solid 3px #f58f00;
  max-width: auto;
}

.tableTitle p{
    font-size: 15px;
    color: #e28400;
}

.tableTitle .impNote{
  font-size: 1.2em !important;
  font-weight: bold;
  color:#DB005B !important;
}

.registrationNoteListItem{
  padding-top: 0 !important;
  padding-left: 5px !important;
  text-align: justify !important;
  line-height: 28px !important;
}
.notesListItemIcon{
  min-width : 30px !important;
}
.brightnessIcon{
  font-size: 8px !important;
  /* color: black; */
}
.regBtns{
  margin-top: 20px;
 display: flex;
flex-direction: row;
}
.registerBtn{
  width: 45%;
  display: inline-block;
}
.registerBtn:hover{
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .registrationBody {
    margin: 0 !important;
    width: 100%;
  }
}

.regBtns a{
  animation: animate 1.5s linear infinite; 
}
.regBtns a p{
  animation: none !important;
}
@keyframes animate { 
  0% { 
      opacity: 0.3; 
  } 

  50% { 
      opacity: 0.9; 
  } 

  100% { 
      opacity: 0.3; 
  } 
} 