.paymentBody {
  margin: 0px auto;
  width: 90%;
  max-width: 950px;
  padding: 30px 100px 100px 100px;
  background-color: #E17800
}

h1 {
  text-align: center;
}
/* 
.feeField{
  padding-bottom: 20px;
  border: 2px solid black;
} */

.feeQue{
  font-size: 1.1em;
}

hr{
  margin: 20px -20px;
}

input[type=radio]{
  margin-right: 3px;
  margin-left: 20px;
}
.payBtnDiv{
  /* display: inline-block;
  margin: 0 300px; */
display: flex;
justify-content: center
}

.payBtn{
height: 50px;
width:300px;
font-size: 1.1em;
}

select
{
  /* background-color: red; */
  border: none;
  height: 25px;
  border-radius: 3px;
}

input:hover, select:hover {
  cursor: pointer;
}

.bill div p:first-of-type{
  width : 560px;
}

.bill div{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.bill div p:last-of-type{
  font-weight: bold;
}

.bill{
  line-height: 25px;
}

.bill h3{
  color: black;
  text-align: center;

}

.bankDetails h3{
  color: black;
  border-bottom: 1px solid white;
}

.bankDetails{
  margin: 0 auto;
  border: 1px solid white;
  line-height: 35px;
  text-align: center;
}

.bankDetails p{
  /* border-bottom: 1px solid white; */
}