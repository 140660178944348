.access{
    margin: 50px auto;
    width: 60%;
}
.access h1{
    text-align: center;
    margin: 30px 0;
    color: #E37900;
}
.access h2{
    margin: 20px 0;
    color: #061D86;
}

.access p{
    margin: 5px;
    font-size: 1.2em
}
@media screen and (max-width:500px) {
    .access{
        margin: 0 !important;
        width: 100%;
    }
}