.visa{
    margin: 50px auto;
    width: 50%;
    text-align: center;
}
.visa h1{
    margin:20px;
    color: #E37900;
}

.visa p{
    font-size: 1.2em;
    line-height: 30px;
}
.mailid{
    color: #061D86;
    font-weight: bold;
}
@media screen and (max-width:500px) {
    .visa{
        margin: 0 !important;
        width: 100%;
    }
}