.keyNote{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.speaker{
  width: 250px;
  height: 350px;
  margin: 30px 40px;
  /* background-color: red; */
}

.pic {
  /* background-color: black; */
  width:230px;
  height:300px;
  margin: 0 auto;
}

.pic img{
  width:250px;
  height: 300px;
  object-fit: contain;
}

.name{
  text-align: center;
  margin: 10px 0;
}

.name a{
  color: black;
}