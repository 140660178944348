
.constructionBgImg{
    width: 70%;
    height: 540px;
    display: block;
    margin : 0 auto;
    opacity: 0.7;
    box-shadow: 1px 1px 18px 0px #404040;
}

@media screen and (max-width:500px) {
    .constructionBgImg{
        width: 95%;
        height: 350px;
        margin-top: 20px;
    }
}