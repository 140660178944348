.venueMap {
  width: 60%;
  margin: 30px auto 100px;
}

.gmap_iframe {
  width: 100%;
}

.venueDetailsDiv {
  text-align: center;
  margin: 0px 0px 30px auto;
  color: aliceblue;
}

.venueDetailsDiv .venueTitle {
  font-size: 30px;
  font-weight: 600;
  /* padding: 20px 0px 10px !important; */
  margin: 0px;
  color: aliceblue;
  padding: 0px 30px;
  
}

.venueDetailsDiv .venueHeader {
  font-size: 30px;
  font-weight: 600;
  margin: 30px 0px !important;
  margin: 0px;
  color: aliceblue;
  display: inline-block;
  padding: 0 0 20px 0;
  width: 80%;
  max-width:900px;
  border-bottom: 2px solid aliceblue;
}

span {
  line-height: 25px;
}

.VenueBgImgDiv {
  background-color: #283840;
  position: relative;
}

.venueBgImg {
  width: 100%;
  opacity: 0.5;
  height: 1085px;
}

.venueDetailsBody {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.venueText{
  display: block;
}

.locImgTitle{
  width: 30%;
  min-width: 330px;
  margin: 10px auto;
  border-radius: 5px;
}
.locImagesDiv{
  width:90%;
  margin: 0 auto;
}
.locImages{
  width:45%;
  height: 270px;
  margin: 10px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 550px) {
  .gmap_iframe {
    height: 300px;
  }

  .venueDetailsDiv .venueHeader {
    font-size: 27px;
    margin: 0 20px;
  }

  .venueDetailsDiv .venueTitle {
    font-size: 27px;
  }

  .venueBgImg {
    height: 1610px;
  }

  .venueMap {
    width: 90%;
    margin: 30px auto 100px;
  }
  
  .locImgTitle{
    width: 80%;
  }
  .locImagesDiv{
    width:95%;
    margin: 0 auto;
  }
  .locImages{
    width:90%;
    height: 250px;
    margin: 10px 0px;
    border-radius: 5px;
  }
}