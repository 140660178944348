.homeDiv {
  padding-bottom: 100px;
}

.HomeBgImgDiv {
  background-color: #000000 !important;
  position: relative;
  font-family: Arial;
  opacity: 1;
}

.homeImg {
  width: 100%;
  min-height: 57vh;
  height: 50%;
  max-height: 65vh;
  opacity: 0.6;
  object-fit: fill;
}

.homePageLogo {
  width: 300px;
  height: 100px;
  border-radius: 10%;
  object-fit: fill;
  margin: 15px;
}

.homePageLogosDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-wrap: wrap;
  /* margin: 17px 0px; */
}

.text-block {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  line-height: 10vh;
  width: 100%;
}

.headingCard {
  color: #160057;
  font-size: 25px;
  font-weight: bolder;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}

.cardTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
  margin-bottom: 20px !important;
  color: #11013e;
  font-family: 'Ubuntu', sans-serif;
}

.cardText {
  padding: 0 40px;
  line-height: 25px;
  text-align: justify;
  color: #160057;

}

.text-block p {
  font-size: 50px;
  font-weight: 600;
  color: white;
  font-family: "Major Mono Display", monospace;
}

.line_1 {
  font-size: 60px !important;
}

.line_5 {
  font-size: 40px !important;
}

.mainLetter {
  font-size: 55px;
  color: #e17800;
}

.bangaloreImagesDiv {
  width: 75%;
  margin: 10px auto;
}

.bangaloreImg {
  max-height: 300px !important;
}

.bangaloreImagesDiv .title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: 30px 0px 10px 0px;
}

@media screen and (max-width: 1350px) {
  .homePageLogo {
    width: 200px;
    height: 80px;
  }

  .headingCard {
    font-size: 22px;
  }
}

@media screen and (max-width: 1150px) {
  .headingCard {
    font-size: 21px;
  }
}

@media screen and (max-height: 600px) {
  .text-block{
    line-height: 8vh;
    margin-top: 5px;
  }
  .line_1 {
    font-size: 45px !important;
  }
  .text-block p{
    font-size: 40px;
  }
  .HomeBgImgDiv{
    height: 340px;
  }
}

@media screen and (max-height: 550px) and (min-width: 1010px) {
  
  .text-block p {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 550px) {

  .homeImg {
    width: 100%;
    min-height: 400px !important;
  }

  .text-block {
    width: 400px;
  }

  .text-block p {
    font-size: 20px;
    line-height: 40px;
  }

  .HomePageCard {
    margin: 0 5px;
  }

  .cardText {
    padding: 0 10px;
  }

  .mainLetter {
    font-size: 25px;
    color: #e17800;
  }

  .homePageLogo {
    width: 200px;
    height: 65px;
    margin: 5px;
  }

  .bangaloreImagesDiv {
    width: 95%;
    margin: 10px auto;
  }

  .line_1 {
    font-size: 25px !important;
  }

  .line_5 {
    font-size: 18px !important;
  }
}


.countDownDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  backdrop-filter: 10px;
  margin: 0 auto;
  width: fit-content;
  padding: 0px 20px !important;
}

/* .countDownDiv .Clock{
  background-color: #000000;
  opacity: 0.5;
} */

.countDownDiv label {
  font-size: 25px;
  font-weight: 900;
  /* border: 2px solid #fff;   */
  padding: 0px 5px;
  background-color: rgba(0, 0, 0, 0.6);
}

.countDownDiv span {
  font-size: 25px;
  font-weight: 900;
}



@media screen and (max-width:1000px) and (min-width:830px) {
  .text-block p {
    font-size: 40px !important;
  }
}

@media screen and (max-width:830px) and (min-width:670px) {
  .text-block p {
    font-size: 37px !important;
  }
}

@media screen and (max-width:670px) and (min-width:530px) {
  .text-block p {
    font-size: 28px !important;
  }
}

@media screen and (max-width:560px) {
  .text-block p {
    font-size: 25px !important;
  }
}

@media screen and (max-width:600px) {

  .countDownDiv label {
    padding: 3px 5px;
    font-size: 16.5px;
    font-weight: 600;
  }

  .countDownDiv span {
    font-size: 16.5px;
    font-weight: 600;
  }
}