.timer{
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
}

.timer {
  color: #ff8a04;
}

.flip-card {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .2);
  border-radius: .1em;
}

.top,
.bottom,
.flip-card .top-flip,
.flip-card .bottom-flip {
  height: .75em;
  line-height: 1;
  padding: .25em;
  overflow: hidden;
}

.top,
.flip-card .top-flip {
  background-color: #f7f7f7;
  border-top-right-radius: .1em;
  border-top-left-radius: .1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bottom,
.flip-card .bottom-flip {
  background-color: white;
  display: flex;
  align-items: flex-end;
  border-bottom-right-radius: .1em;
  border-bottom-left-radius: .1em;
}

.flip-card .top-flip {
  position: absolute;
  width: 100%;
  animation: flip-top 250ms ease-in;
  transform-origin: bottom;
}

@keyframes flip-top {
  100% {
    transform: rotateX(90deg);
  }
}

.flip-card .bottom-flip {
  position: absolute;
  bottom: 0;
  width: 100%;
  animation: flip-bottom 250ms ease-out 250ms;
  transform-origin: top;
  transform: rotateX(90deg);
}

@keyframes flip-bottom {
  100% {
    transform: rotateX(0deg);
  }
}

.timer {
  display: flex;
  gap: .5em;
  justify-content: center;
}

.timer-segment {
  display: flex;
  flex-direction: column;
  gap: .1em;
  align-items: center;
}

.segment {
  display: flex;
  gap: .1em;
}

.segment-title {
  font-size: 1rem;
  /* height: 20px; */
  line-height: 15px !important;

}

@media screen and (max-height: 550px) {
  .timer {
    font-size: 1.5rem;
  }
}