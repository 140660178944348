.modal{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height:100%;
  background: rgba(0,0,0,0.7);
  z-index: 999; 
  display: flex;
  justify-content: center;
  align-items: start;
}

.modal-content{
  margin: 70px;
  background: #011c52;
  border-radius: 5px;
  display: inline-block;
  width: 500px;
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  line-height: 35px;
  color: white;
}


.modal-content button {
  font-size: 1em;
  width: 50px;
  margin: 10px;
  box-shadow: none;
  outline: none;
}

.modal-content button:hover {
  cursor: pointer;
}