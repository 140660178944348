.submission{
    width : 70%;
    margin: 30px auto;
    min-height: 800px;
    line-height: 28px;
}

.submission .title{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}


.submissionNote{
    margin : 20px 0 !important;
    text-align: justify;
    text-indent: 25px;
}
.submissionNote1{
    margin : 20px 0 !important;
    text-align: justify;
}
.templateLink{
    color: blue;
    text-decoration: none;
    text-indent: 25px;
    padding-bottom: 20px !important;
}
.templateLink:hover{
    color:rgb(0, 0, 112)
}
.step{
    display: flex;
    column-gap: 15px;
    margin-top: 15px;
    margin-left: 10px;
}


.step p{
    line-height: 30px;
}
.step svg{
   margin-top: 3px;
}


.steps{
    margin-top: 20px;
}
.stepnum{
    font-weight: 550 !important;
}

.stepnote{
    text-align: justify;
    width: 90%;
    margin: 0 auto;
}
.stepslist{
    padding: 0 !important;
}
@media screen and (max-width:500px) {
    .submission{
        width:94%
    }

    .submissionNote1{
        text-align: start;
    }
    stepnote{
        width: 100%;
    }
}
