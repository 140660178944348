.singleSpeaker {
  margin: 50px auto;
  width: 55%;
  min-width: 800px;
  text-align: justify;
}

.firstRow{
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 30px;
}

.bio{
  margin-left: 30px;
  /* height: 250px; */
}

.singleSpeaker p{
  font-size: 1.1em;
  line-height: 25px;
  text-align: justify;
}

.pic {
  /* background-color: black; */
  width: 230px;
  margin: 0 auto;
}

.pic img {
  width: 100%;
}

.name {
  text-align: center;
  margin: 10px 0;
}

.name a {
  color: black;
}

@media screen and (max-width:00px) {
  .program{
      margin: 0 !important;
      width: 100%;
      text-align: center;
      padding-top: 40px;
  }
  .singleSpeaker {
    width: 90% !important;
  }
  .firstRow{
    flex-wrap: wrap;
  }
}