.program{
    margin: 50px auto;
    width: 70%;
    text-align: center;

}

.blinking{
    background-color: coral;
    padding: 5px 10px;
    border-radius: 13px;
    text-decoration: none;
    animation: blink 1s linear infinite;
}
@keyframes blink{
    0%{opacity: 1;color:red; }
    50%{opacity: 1;}
    100%{opacity: 1;color:;}
    }

@media screen and (max-width:500px) {
    .program{
        margin: 0 !important;
        width: 100%;
        text-align: center;
        padding-top: 40px;
    }
}